import { put, call, takeEvery } from "redux-saga/effects";
import * as apis from "../../network/apis/applicants";
import * as types from "./types";
import * as actions from "./actions";
import { showErrorMsg, showSuccessMsg } from "../../utils/Helpers";

function* getApplicantsListSaga({ payload }) {
  try {
    yield put(
      actions.getApplicantsResponse({
        list: [],
        meta: {},
      })
    );
    const response = yield call(apis.getApplicantsList, payload);
    yield put(
      actions.getApplicantsResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getApplicantsRequest({
          ...payload,
          page: +response.headers["total-pages"],
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

function* getApplicantSaga({ payload }) {
  try {
    const response = yield call(apis.getApplicant, +payload.id);
    yield put(actions.getApplicantResponse(response.data.data));
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* updateApplicantSaga({ payload }) {
  try {
    const response = yield call(apis.updateApplicant, payload);
    yield put(actions.getApplicantResponse(response.data.data));
    yield showSuccessMsg(
      payload?.params?.is_blocked
        ? "blockedSuccessfully"
        : "unBlockedSuccessfully"
    );
  } catch (err) {
    yield showErrorMsg(err.response?.data?.message);
  }
}

function* getAEligiblepplicantsListSaga({ payload }) {
  try {
    yield put(
      actions.getEligibleApplicantsResponse({
        list: [],
        meta: {},
      })
    );
    const response = yield call(apis.getEligibleApplicantsList, payload);
    yield put(
      actions.getEligibleApplicantsResponse({
        list: response.data.data,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"],
        },
      })
    );
    if (+response.headers["current-page"] > +response.headers["total-pages"]) {
      yield put(
        actions.getEligibleApplicantsRequest({
          ...payload,
          params: {
            ...payload.params,
            page: +response.headers["total-pages"],
          },
        })
      );
    }
  } catch (error) {
    yield error.response && showErrorMsg(error.response?.data?.message);
  }
}

export default function* ApplicantsSagas() {
  yield takeEvery(types.GET_APPLICANTS_REQUEST, getApplicantsListSaga);
  yield takeEvery(types.GET_APPLICANT_REQUEST, getApplicantSaga);
  yield takeEvery(types.UPDATE_APPLICANT, updateApplicantSaga);
  yield takeEvery(
    types.GET_ELIGIBLE_APPLICANTS_REQUEST,
    getAEligiblepplicantsListSaga
  );
}
