import { axiosInstance, axiosUserAppInstance } from "./";

const getLookups = async (lookups) =>
  await axiosInstance.get("/lookups", { params: { keys: lookups.join(",") } });

const getLookupsSuperVisor = async (lookups) =>
  await axiosUserAppInstance.get("/lookups", {
    params: { keys: lookups.join(",") },
  });

const getCountries = async (params) =>
  await axiosInstance.get("/countries", { params });

const getCities = async (params) =>
  await axiosInstance.get("/cities", { params });

const getProjectManagers = async (params) =>
  await axiosInstance.get("/project_managers", { params });

const getEventZones = async (id) =>
  await axiosInstance.get(`/events/${id}/zones`, { params: { page: -1 } });

export {
  getLookups,
  getLookupsSuperVisor,
  getCountries,
  getCities,
  getProjectManagers,
  getEventZones,
};
