import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TextField, FormHelperText, Autocomplete, Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

import messages from "../../../assets/locale/messages";
import "./FilterInput.scss";

const FilterInput = ({
  value,
  inputClassName,
  disabled,
  options,
  placeholder,
  label,
  id,
  name,
  onChange,
  helperText,
  helperTextClass,
  filterWrapperClass,
  isMultiple,
  isOptionsElt,
}) => {
  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  return (
    <div
      className={`filter-wrapper ${filterWrapperClass} ${
        isMultiple && value.length > 0 && "multiple-filter-wrapper-padding"
      }`}>
      <Autocomplete
        name={name}
        id={id || name}
        options={options}
        disabled={disabled}
        multiple={isMultiple}
        getOptionLabel={(option) => option.name || ""}
        renderOption={(props, option) => (
          <Box component="li" key={option.id} {...props}>
            {isOptionsElt ? (
              <>
                {option.icon}
                <span className="mx-1">{option.name}</span>
              </>
            ) : (
              option.name
            )}
          </Box>
        )}
        isOptionEqualToValue={(option, value) => option.id == value.id}
        onChange={(e, value) => {
          onChange(value);
        }}
        className={`${inputClassName}`}
        value={value}
        noOptionsText={shared.noOptions}
        popupIcon={<KeyboardArrowDown />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            label={label || placeholder}
            color="primary"
            className="unify-input-padding"
          />
        )}
      />
      {helperText && (
        <FormHelperText className={helperTextClass || "font-medium fsize-12"}>
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
};

export default FilterInput;

FilterInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inputClassName: PropTypes.string,
  filterWrapperClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.element,
    })
  ),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helperTextClass: PropTypes.string,
  value: PropTypes.any,
  isMultiple: PropTypes.bool,
  isOptionsElt: PropTypes.bool,
};
