import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Divider } from "@mui/material";
import { Block } from "@mui/icons-material";

import Breadcrumbs from "../../../components/BreadCrumbs";
import Rate from "../../../components/Rate";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import messages from "../../../assets/locale/messages";
import Profile from "./Profile";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Avatar from "../../../components/Avatar";
import {
  getApplicantRequest,
  getApplicantResponse,
  updateApplicant,
} from "../../../store/applicants/actions";
import "./ViewApplicant.scss";

const ViewApplicant = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const lang = useSelector((state) => state.locale.lang);
  const applicant = useSelector((state) => state.applicants.applicant);
  const { applicants, shared } = messages[lang];

  const [openActionModal, setOpenActionModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [showImgPopup, setShowImgPopup] = useState(false);

  const handleConfirmModalAction = () => {
    let params = {
      is_blocked: modalType === "block",
    };
    dispatch(
      updateApplicant({
        id,
        params,
      })
    );
    setOpenActionModal(false);
  };

  const getApplicant = () => {
    dispatch(
      getApplicantRequest({
        id,
      })
    );
  };

  useEffect(() => {
    getApplicant();
  }, [id, lang]);

  useEffect(() => {
    return () => {
      dispatch(getApplicantResponse({}));
    };
  }, []);

  return (
    <div className="applicant-details-page-container">
      <div className="w-100 page-title-margin d-flex justify-content-between align-items-center">
        <Breadcrumbs
          list={[
            {
              path: ROUTE_PATHS.applicants,
              label: applicants.listApplicants.listApplicantsBreadcrumb,
            },
            {
              path: "",
              label: applicant?.["full_name_" + lang],
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />
        {!applicant?.is_blocked ? (
          <div className="btn-container">
            <Button
              type="button"
              onClick={() => {
                setModalType("block");
                setOpenActionModal(true);
              }}
              label={shared.block}
              className="btn-block"
              outlined
              negative
              small
            />
          </div>
        ) : (
          <div className="btn-container">
            <Button
              type="button"
              onClick={() => {
                setModalType("unBlock");
                setOpenActionModal(true);
              }}
              label={shared.unBlock}
              className="btn-unblock"
              outlined
              positive
              small
            />
          </div>
        )}
      </div>
      <div>
        <div className="d-flex bg-white p-4 mb-md-4 mb-3 rounded-2">
          <div className="row w-100">
            <div className="col-12 col-md-2 col-xl-1 mb-3 mb-md-0">
              <Avatar
                imgSrcAlt="user name"
                imgSrc={applicant?.image}
                name={applicant?.["full_name_" + lang]}
                size={70}
                containerClass="pointer"
                onClick={() => {
                  if (applicant.image) setShowImgPopup(true);
                }}
              />
            </div>
            <div className="col-12 col-md-10 col-xl-11">
              <p className="fsize-20 fweight-500 mb-0 d-flex align-items-center">
                {applicant?.["full_name_" + lang]}

                {applicant?.is_blocked && (
                  <Block color="error" className="mx-3" fontSize="large" />
                )}
              </p>
              <p className="fsize-16 mb-0 text-black-50 pb-1">
                <span>{shared.id}: </span>
                <span>{applicant?.id}</span>
              </p>
              <p className="fsize-16 mb-0 text-black-50 pb-2">
                {applicant?.role?.name}
              </p>

              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="d-flex mb-4 mb-md-0">
                  <div className="rating-container pe-2 pe-sm-4">
                    <p className="mb-0 fweight-600 fsize-16 d-flex">
                      <Rate
                        rating={
                          applicant.rating ? applicant.rating.toFixed(2) : 0
                        }
                      />
                    </p>
                    <span className="fsize-14 text-black-50">
                      {applicants.applicantDetails.rated}
                    </span>
                  </div>
                  <Divider orientation="vertical" flexItem />
                  <div className="px-2 px-sm-4">
                    <p className="mb-0 fweight-600 fsize-16">
                      {applicant?.participated_in_events_count}
                    </p>
                    <span className="fsize-14  text-black-50">
                      {shared.events}
                    </span>
                  </div>
                  <Divider orientation="vertical" flexItem />
                  <div className="total-hours-container ps-2 ps-sm-4">
                    <p className="mb-0 fweight-600 fsize-16">0 h</p>
                    <span className="fsize-14 text-black-50">
                      {applicants.applicantDetails.totalHours}
                    </span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="rating-container pe-2 pe-sm-4">
                    <a
                      className="text-decoration-underline text-main pointer"
                      onClick={() =>
                        history.push(
                          ROUTE_PATHS.viewApplicantRequests.replace(":id", id)
                        )
                      }
                    >
                      {applicants.applicantDetails.pendingRequests}
                    </a>
                  </div>
                  <Divider orientation="vertical" flexItem />
                  <div className="px-2 px-sm-4">
                    <a
                      className="text-decoration-underline text-main pointer"
                      onClick={() =>
                        history.push(
                          ROUTE_PATHS.viewApplicantEvents.replace(":id", id)
                        )
                      }
                    >
                      {applicants.applicantDetails.showEvents}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <Profile applicant={applicant} />
        </div>
        <Modal
          open={showImgPopup}
          handleClose={() => setShowImgPopup(false)}
          modalClass="profile-img-popup-modal"
          isContentText={false}
          content={
            <>
              <img
                src={applicant?.image}
                alt="applicant profile image"
                className="img-container"
              />
            </>
          }
        />
        <Modal
          open={openActionModal}
          handleClose={() => {
            setOpenActionModal(false);
          }}
          content={
            modalType == "block"
              ? applicants.applicantDetails.blockModalConfirmMessage
              : applicants.applicantDetails.unBlockModalConfirmMessage
          }
          hasActions
          confirmBtnTxt={modalType == "block" ? shared.confirm : shared.unBlock}
          cancelBtnTxt={shared.cancel}
          handleConfirm={handleConfirmModalAction}
          handleCancel={() => {
            setOpenActionModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default ViewApplicant;
