export const ROUTE_PATHS = {
  login: "/login",
  forgetPassword: "/forget-password",
  changePassword: "/change-password",
  resetPassword: "/profile/reset-password",

  home: "/home",
  events: "/events",
  createEvent: "/events/create",
  viewEvent: "/events/:id/view",
  inviteApplicants: "/events/:id/view/invite-applicants",
  editEvent: "/events/:id/edit",
  employees: "/employees",
  viewEmployee: "/employees/:id/view",
  profile: "/profile",
  editProfile: "/profile/edit",
  requests: "/joining-requests",
  promotionRequests: "/promotions-requests",
  addProjectManager: "/employees/add-project-manager",
  applicants: "/applicants",
  viewApplicant: "/applicants/:id",
  viewApplicantRequests: "/applicants/:id/pending-requests",
  viewApplicantEvents: "/applicants/:id/events",
  viewApplicantEvaluationView: "/applicants/:id/events/evaluation/:formid/view",
  viewApplicantEvaluationEdit: "/applicants/:id/events/evaluation/:formid/edit",
  timesheet: "/timesheet",
  monthlyReport: "/timesheet/:eventid/monthly-report",
  dailyReport: "/timesheet/:eventid/daily-report",
  applicantReport: "/timesheet/:eventid/applicant-report/:userid",
  zoneApplicants: "/events/:eventId/zones/:zoneId/applicants",
  evaluations: "/evaluations",
  inprogressEvaluations: "/evaluations/in-progress/:id",
  completedEvaluations: "/evaluations/completed/:id",
  evaluationFormInprogressCreate:
    "/evaluations/in-progress/:id/form/:formid/edit",
  evaluationFormInprogressView:
    "/evaluations/in-progress/:id/form/:formid/view",
  evaluationFormCompletedView: "/evaluations/completed/:id/form/:formid/view",
  notifications: "/notification",
};
