import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Menu from "../../components/Menu";
import Breadcrumbs from "../../components/BreadCrumbs";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import EmptyState from "../../components/EmptyState";
import messages from "../../assets/locale/messages";
import { formatDate } from "../../utils/Helpers";
import emptyPlaceholder from "../../assets/images/timesheet-screen/empty-report-placeholder.svg";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import ApplicantReportModal from "./ApplicantReportModal/ApplicantReportModal";
import {
  getApplicantstTimeSheetRequests,
  getApplicantstTimeSheetResponse,
} from "../../store/ApplicantsTimeSheet/actions";
import Button from "../../components/Button";
import DeleteApplicantReportModal from "./DeleteApplicantReportModal/DeleteApplicantReportModal";

const ApplicantReport = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const { timeSheet, shared } = messages[lang];
  const history = useHistory();

  const [selectedOption, setSelectedOption] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const list = useSelector((state) => state.applicantsTimeSheetRequest.list);

  const meta = useSelector((state) => state.applicantsTimeSheetRequest.meta);
  const isLoading = useSelector((state) => state.loader);
  const [page, setPage] = useState(1);

  const { eventid, userid } = useParams();

  let location = useLocation();

  const editDeleteActions = [shared.edit, shared.delete];
  const clickHandler = (option, item) => {
    setOpenStatusModal(true);
    setSelectedOption(option);
    setSelectedItem(item);
  };
  const handleClose = (status) => {
    setOpenStatusModal(status);
    setSelectedOption(null);
    setSelectedItem(null);
  };

  useEffect(() => {
    const params = {
      page,
      items: 10,
      event_id: eventid,
      user_id: userid,
      order_by_check_in_at: "desc",
    };
    if (location?.state?.isDaily) {
      // Removed currently to get all days records in applicant record
      // params.check_in_day = location?.state?.filter_date;
    } else {
      params.check_in_month_in_a_date = location?.state?.filter_date;
    }
    dispatch(getApplicantstTimeSheetRequests(params));
  }, [page, lang]);

  useEffect(() => {
    return () => {
      dispatch(
        getApplicantstTimeSheetResponse({
          list: [],
          meta: {},
        })
      );
    };
  }, []);

  const renderMonthlyReportsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 d-flex justify-content-center align-items-center flex-column my-4">
            <EmptyState
              description={timeSheet.placeholder}
              imgSrc={emptyPlaceholder}
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={[
                timeSheet.date,
                timeSheet.checkin,
                timeSheet.checkout,
                timeSheet.applicantReport.workingHours,
                shared.actions,
              ]}
              hasActions={false}
              hasSizeLimitation={false}
              rows={list?.map((row, i) => (
                <TableRow
                  key={i}
                  className={`${
                    row.is_checked_out_automatically ? "flagged-row" : ""
                  }`}>
                  <TableCell>{row.check_in_readable_date}</TableCell>
                  <TableCell>
                    {row?.check_in_at
                      ? formatDate(row?.check_in_at, "HH:mm:ss")
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {row?.check_out_at
                      ? formatDate(row?.check_out_at, "HH:mm:ss")
                      : "-"}
                  </TableCell>
                  <TableCell
                    className={`mobile-style ${lang === "ar" && "text-end"}`}
                    dir="ltr">
                    {row.working_hours}
                  </TableCell>
                  <TableCell className="mobile-style">
                    <Menu
                      options={editDeleteActions}
                      onSelectItem={(option) => clickHandler(option, row)}
                      triggerIcon={<MoreVertIcon />}
                      paperProps={{
                        style: {
                          width: "15ch",
                          color: "#6B778C",
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            />
          </div>
          <div className="d-flex justify-content-center py-3 mt-4">
            {meta.pages > 1 && (
              <Pagination
                count={meta.pages}
                page={meta.currentPage || page}
                handleChange={(event, page) => {
                  setPage(page);
                }}
                defaultPage={1}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="work-slots-list-container">
      <div className="d-flex justify-content-between my-3 flex-column flex-md-row">
        <Breadcrumbs
          list={[
            {
              path: ROUTE_PATHS.timesheet,
              label: timeSheet.listTimeSheetBreadcrumb,
            },
            {
              path: "",
              label: `${
                timeSheet[
                  location?.state?.isDaily ? "dailyReport" : "monthlyReport"
                ].name
              } - ${location?.state?.event_name}`,
              isClicked: true,
              onclick: () =>
                history.push(
                  ROUTE_PATHS[
                    location?.state?.isDaily ? "dailyReport" : "monthlyReport"
                  ].replace(":eventid", eventid),
                  { event_name: location?.state?.event_name }
                ),
            },

            {
              path: "",
              label:
                lang === "ar"
                  ? location?.state?.user_name?.full_name_ar
                  : location?.state?.user_name?.full_name_en,
            },
          ]}
          BreadCrumbHasBackIcon={false}
        />

        <Button
          label={timeSheet.addStatus}
          labelClass="px-4"
          className="mx-2 btns continue-btn add-slot-timesheet"
          onClick={() => clickHandler("Add")}
        />
      </div>

      <div className="d-flex flex-column justify-content-between">
        {renderMonthlyReportsList()}
      </div>

      {selectedOption === "Delete" || selectedOption === "حذف" ? (
        <DeleteApplicantReportModal
          open={openStatusModal}
          selectedItem={selectedItem}
          eventId={eventid}
          userId={userid}
          handleClose={handleClose}
          selectedOption={selectedOption}
          isDaily={location?.state?.isDaily}
          dateParam={location?.state?.filter_date}
        />
      ) : (
        <ApplicantReportModal
          handleClose={handleClose}
          open={openStatusModal}
          selectedOption={selectedOption}
          selectedItem={selectedItem}
          isDaily={location?.state?.isDaily}
          dateParam={location?.state?.filter_date}
        />
      )}
    </div>
  );
};

export default ApplicantReport;
