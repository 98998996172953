import * as types from "./types";

export const getApplicantsRequest = (payload) => ({
  type: types.GET_APPLICANTS_REQUEST,
  payload,
});
export const getApplicantsResponse = (payload) => ({
  type: types.GET_APPLICANTS_RESPONSE,
  payload,
});

export const getApplicantRequest = (payload) => ({
  type: types.GET_APPLICANT_REQUEST,
  payload,
});
export const getApplicantResponse = (payload) => ({
  type: types.GET_APPLICANT_RESPONSE,
  payload,
});

export const updateApplicant = (payload) => ({
  type: types.UPDATE_APPLICANT,
  payload,
});

export const getEligibleApplicantsRequest = (payload) => ({
  type: types.GET_ELIGIBLE_APPLICANTS_REQUEST,
  payload,
});
export const getEligibleApplicantsResponse = (payload) => ({
  type: types.GET_ELIGIBLE_APPLICANTS_RESPONSE,
  payload,
});
