const requests = {
  listRequests: {
    list: "List Events Requests",
    search: "Search by applicant name, event name",
    status: "Status",
    eventName: "Event Name",
    applicantName: "Applicant Name",
    date: "Date",
    rating: "Rating",
    all: "All",
    approved: "Accepted",
    pending: "Pending",
    cancelled: "Cancelled",
    auto_cancelled: "Auto Cancelled",
    hint: "To change the role before approving the request, edit the dropdown selection",
    placeholder: "No requests received yet!",
    warningMessage:
      "The maximum number for this specific gender has been completed, Are you sure you want to continue?",
    warningTitle: "Approval Warning",
    cityOfResidence: "City",
    changeRoleTitle: "Change Role For ",
  },
};

export default requests;
