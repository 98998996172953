import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import logo from "../../../assets/images/side-menu/proline-sidemenu-logo.png";
import { ROUTE_PATHS } from "../../../utils/RoutesPaths";
import { getDeviceId } from "../../../utils/Auth";
import {
  // HomeIcon,
  EventsIcon,
  EmployeesIcon,
  RequestsIcon,
  JoiningRequestsIcon,
  LogOutIcon,
  ApplicantsIcon,
  EvaluationsIcon,
  TimesheetIcon,
  PromotionsIcon,
} from "../../../utils/Icons";
import { isAdmin, isSuperAdmin } from "../../../utils/Permissions";
import messages from "../../../assets/locale/messages";
import { logoutRequest } from "../../../store/Auth/actions";
import "./SideMenu.scss";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const SideMenu = ({
  detectSideMenuOpen,
  closeMobileSideMenu,
  drawerWidth,
  mobileSideMenuOpen,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.locale.lang);
  const { shared } = messages[lang];

  const [open, setOpen] = useState(true);
  const [path, setPath] = useState("");
  const [openItemsIds, setOpenItemsIds] = useState([2]);

  const handleItemClick = (itemId) => {
    if (openItemsIds.includes(itemId)) {
      setOpenItemsIds(openItemsIds.filter((item) => item !== itemId));
    } else {
      setOpenItemsIds([...openItemsIds, itemId]);
    }
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location, setPath]);

  useEffect(() => {
    detectSideMenuOpen(open);
  }, [detectSideMenuOpen, open]);

  const handleSideMenuOpen = () => {
    setOpen(true);
  };

  const handleSideMenuClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(
      logoutRequest({
        device_id: getDeviceId(),
      })
    );
  };

  const activeRoute = (activekeys) => {
    return activekeys && activekeys.some((key) => path.includes(key));
  };

  const sideMenuItems = [
    // {
    //   id: 1,
    //   title: shared.home,
    //   icon: <HomeIcon />,
    //   iconSelected: <HomeIcon selectedIcon />,
    //   slug: ROUTE_PATHS.home,
    //   activekeys: ["home"],
    //   hasChildren: false,
    //   children: [],
    // },
    {
      id: 7,
      title: shared.evaluations,
      icon: <EvaluationsIcon />,
      iconSelected: <EvaluationsIcon selectedIcon />,
      slug: ROUTE_PATHS.evaluations,
      activekeys: ["evaluations"],
      hasChildren: false,
      children: [],
    },
  ];

  if (isAdmin() && isSuperAdmin()) {
    sideMenuItems.unshift({
      id: 3,
      title: shared.employees,
      icon: <EmployeesIcon />,
      iconSelected: <EmployeesIcon selectedIcon />,
      slug: ROUTE_PATHS.employees,
      activekeys: ["employees"],
      hasChildren: false,
      children: [],
    });
  }

  if (isAdmin()) {
    sideMenuItems.unshift(
      {
        id: 2,
        title: shared.events,
        icon: <EventsIcon />,
        iconSelected: <EventsIcon selectedIcon />,
        slug: ROUTE_PATHS.events,
        activekeys: ["events"],
        hasChildren: false,
        children: [],
      },
      {
        id: 4,
        title: shared.requests,
        icon: <RequestsIcon />,
        iconSelected: <RequestsIcon selectedIcon />,
        hasChildren: true,
        children: [
          {
            id: 4.1,
            title: shared.joiningEvents,
            icon: <JoiningRequestsIcon />,
            iconSelected: <JoiningRequestsIcon selectedIcon />,
            slug: ROUTE_PATHS.requests,
            activekeys: ["joining-requests"],
          },
          isSuperAdmin()
            ? {
                id: 4.2,
                title: shared.promotions,
                icon: <PromotionsIcon />,
                iconSelected: <PromotionsIcon selectedIcon />,
                slug: ROUTE_PATHS.promotionRequests,
                activekeys: ["promotions-requests"],
              }
            : null,
        ],
      },
      {
        id: 5,
        title: shared.applicants,
        icon: <ApplicantsIcon />,
        iconSelected: <ApplicantsIcon selectedIcon />,
        slug: ROUTE_PATHS.applicants,
        activekeys: ["applicants"],
        hasChildren: false,
        children: [],
      },
      {
        id: 6,
        title: shared.timesheet,
        icon: <TimesheetIcon />,
        iconSelected: <TimesheetIcon selectedIcon />,
        slug: ROUTE_PATHS.timesheet,
        activekeys: ["timesheet"],
        hasChildren: false,
        children: [],
      }
    );
  }

  const renderSideMenuItems = (device) => (
    <div className="side-menu-container">
      <DrawerHeader className="justify-content-between">
        {device === "mobile" ? (
          <>
            <img src={logo} alt="proline-logo" className="mb-0 mx-3 logo" />

            <IconButton
              onClick={closeMobileSideMenu}
              className="close-side-menu-icon p-0">
              <Close />
            </IconButton>
          </>
        ) : (
          <>
            {open && (
              <>
                <img src={logo} alt="proline-logo" className="mb-0 mx-3 logo" />
              </>
            )}
            <IconButton
              onClick={open ? handleSideMenuClose : handleSideMenuOpen}
              className="side-menu-toggle-icon">
              {lang === "en" ? (
                open ? (
                  <ChevronLeft />
                ) : (
                  <ChevronRight />
                )
              ) : open ? (
                <ChevronRight />
              ) : (
                <ChevronLeft />
              )}
            </IconButton>
          </>
        )}
      </DrawerHeader>
      <List className="side-menu-list">
        {sideMenuItems.map(
          ({
            id,
            title,
            icon,
            iconSelected,
            slug,
            activekeys,
            hasChildren,
            children,
          }) =>
            hasChildren ? (
              <div key={id}>
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  className="list-item"
                  onClick={() => handleItemClick(id)}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className="list-item-wrapper">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: lang === "en" && open ? 2 : "auto",
                        ml: lang === "ar" && open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                      lg={{
                        minWidth: 0,
                        mr: lang === "en" && open ? 3 : "auto",
                        ml: lang === "ar" && open ? 3 : "auto",
                        justifyContent: "center",
                      }}>
                      {activekeys && activeRoute(activekeys)
                        ? iconSelected
                        : icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={title}
                      sx={{
                        display: open ? "block" : "none",
                        textAlign: "start",
                      }}
                      className="list-item-label"
                    />
                    {openItemsIds.includes(id) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  in={openItemsIds.includes(id)}
                  timeout="auto"
                  unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    className={`child-sub-list ${!open ? "closed-menu" : ""}`}>
                    {children.map(
                      (child, index) =>
                        child && (
                          <Link to={child.slug} key={index}>
                            <ListItem
                              selected={activeRoute(child.activekeys)}
                              disablePadding
                              sx={{ display: "block" }}
                              className="list-item">
                              <ListItemButton
                                sx={{
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  px: 2.5,
                                }}
                                className={`list-item-wrapper ${
                                  !open ? "closed-menu" : ""
                                }`}>
                                <ListItemIcon
                                  sx={{
                                    // TODO: change 20 to 0 after adding children icons
                                    minWidth: 20,
                                    mr: lang === "en" && open ? 1 : "auto",
                                    ml: lang === "ar" && open ? 1 : "auto",
                                    justifyContent: "center",
                                  }}>
                                  {child.activekeys &&
                                  activeRoute(child.activekeys)
                                    ? child.iconSelected
                                    : child.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={child.title}
                                  sx={{
                                    display: open ? "block" : "none",
                                    textAlign: "start",
                                  }}
                                  className="list-item-label"
                                />
                              </ListItemButton>
                            </ListItem>
                          </Link>
                        )
                    )}
                  </List>
                </Collapse>
              </div>
            ) : (
              <Link to={slug} key={id}>
                <ListItem
                  selected={activeRoute(activekeys)}
                  disablePadding
                  sx={{ display: "block" }}
                  className="list-item">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className={`list-item-wrapper ${
                      !open ? "closed-menu" : ""
                    }`}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: lang === "en" && open ? 2 : "auto",
                        ml: lang === "ar" && open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                      lg={{
                        minWidth: 0,
                        mr: lang === "en" && open ? 3 : "auto",
                        ml: lang === "ar" && open ? 3 : "auto",
                        justifyContent: "center",
                      }}>
                      {activekeys && activeRoute(activekeys)
                        ? iconSelected
                        : icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={title}
                      sx={{
                        display: open ? "block" : "none",
                        textAlign: "start",
                      }}
                      className="list-item-label"
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            )
        )}
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          className={`list-item ${!open ? "closed-menu" : ""}`}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            className={`list-item-wrapper ${!open ? "closed-menu" : ""}`}
            onClick={handleLogout}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: lang === "en" && open ? 2 : "auto",
                ml: lang === "ar" && open ? 2 : "auto",
                justifyContent: "center",
              }}
              lg={{
                minWidth: 0,
                mr: lang === "en" && open ? 3 : "auto",
                ml: lang === "ar" && open ? 3 : "auto",
                justifyContent: "center",
              }}>
              <LogOutIcon />
            </ListItemIcon>
            <ListItemText
              primary={shared.logout}
              sx={{ display: open ? "block" : "none", textAlign: "start" }}
              className="list-item-label"
            />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const desktopMenuCustomStyle = {
    display: { xs: "none", md: "block" },
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: (theme) =>
        theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        width: (theme) => ({
          xs: theme.spacing(7),
          sm: theme.spacing(9),
        }),
      }),
    },
  };

  const mobileMenuCustomStyle = {
    display: { xs: "block", md: "none" },
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
  };

  return (
    <Box
      component="nav"
      aria-label="mailbox folders"
      className="side-menu position-fixed">
      <Drawer
        variant="temporary"
        open={mobileSideMenuOpen}
        onClose={closeMobileSideMenu}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={mobileMenuCustomStyle}
        className="mobile-side-menu"
        anchor={lang === "en" ? "left" : "right"}>
        {renderSideMenuItems("mobile")}
      </Drawer>
      <Drawer
        variant="permanent"
        open={open}
        sx={desktopMenuCustomStyle}
        className="desktop-side-menu">
        {renderSideMenuItems("desktop")}
      </Drawer>
    </Box>
  );
};

SideMenu.propTypes = {
  detectSideMenuOpen: PropTypes.func.isRequired,
  closeMobileSideMenu: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  mobileSideMenuOpen: PropTypes.bool.isRequired,
};

export default SideMenu;
