const timeSheet = {
  eventName: "أسم الفاعلية",
  startDate: "تاريخ البدء",
  endDate: "تاريخ الانتهاء",
  type: "نوع الفاعلية",
  link: "رابط التقرير",
  status: "الحالة",
  listTimeSheetBreadcrumb: "قائمة ساعات عمل الفاعليات",
  search: "ابحث باستخدام اسم الفعاليه",
  role: "الوظيفة",
  attendanceReport: "تقرير الحضور",
  placeholder: "لا يوجد تقارير",
  date: "التاريخ",
  applicantName: "اسم المتقدم",
  checkin: "وقت تسجيل الوصول",
  checkout: "وقت تسجيل الخروج",
  checkinPlaceHolder: "ادخل وقت تسجيل الدخول",
  checkoutPlaceHolder: "ادخل وقت تسجيل الخروج",
  datePlaceholder: "ادخل تاريخ تسجييل الدخول",
  EditStatus: "تعديل الحالة ل",
  addStatus: "اضف التوقيت",
  addStatusFor: "اضف التوقيت ل ",
  searchByApplicant: "ابحث باستخدام اسم المتقدم",
  payroll: "الراتب المتوقع",
  numberOfWorkingDays: "عدد أيام العمل",
  dailyReport: {
    name: "تقرير الفاعليه اليومي",
    workingHours: "إجمالي ساعات العمل",
    endDateValidation: "لا يمكن أن يكون تاريخ الانتهاء قبل تاريخ البدء",
  },
  monthlyReport: {
    name: "تقرير الفاعليه الشهري",
    workingHours: " إجمالي ساعات العمل شهريا",
    month: "شهر",
  },
  applicantReport: {
    name: "تقرير مقدم الطلب",
    workingHours: "ساعات العمل اليوميه",
  },
  deleteMsg: " هل انت متاكد من حذف التقرير ؟ ",
  exportBtn: "تصدير التقارير إلى ملف اكسل",
  selectColumnsToExport: "اختر الأعمدة المرغوبة ليتم تصديرها",
  exportErr: "يجب أن تختار عمودا واحدا علي الأقل",
  dailyPayroll: "الراتب/اليوم",
  dailyExportSheet: {
    nameAr: "الاسم بالعربية",
    nameEn: "الاسم بالانجليزية",
    role: "الوظيفة",
    nationalIqama: "رقم الهوية/رقم الإقامة",
    nationality: "الهوية",
    mobile: "رقم الهاتف المحمول",
    email: "البريد الإلكترونى",
    birthDate: "تاريخ الميلاد",
    workingDays: "عدد أيام العمل",
    totalWorkingHours: "اجمالي ساعات العمل",
    payrollDay: "الراتب/اليوم",
    expectedPayroll: "الراتب المتوقع",
    iban: "رقم الحساب المصرفي الدولي",
    signature: "التوقيع",
  },
};

export default timeSheet;
