import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import messages from "../../../../../assets/locale/messages";
import Accordion from "../../../../../components/Accordion";
import Button from "../../../../../components/Button";
import { ROUTE_PATHS } from "../../../../../utils/RoutesPaths";
import ViewInputData from "../../../../../components/ViewInputData";

const ViewZonesFields = ({ zone }) => {
  const lang = useSelector((state) => state.locale.lang);
  const event = useSelector((state) => state.events.event);
  const history = useHistory();
  const { events, zoneApplicants } = messages[lang];

  const renderPhoneNumber = (dialCode, phone) => (
    <div>
      <span>{dialCode}</span>
      <span className="mx-1">{phone}</span>
    </div>
  );

  return (
    <>
      <Accordion
        title={zone.name}
        content={
          <>
            <h4 className="fsize-18 fweight-600 black-title">
              {events.viewEvents.managerInfo}
            </h4>
            <ViewInputData
              label={events.creatEvent.labels.managerName}
              content={zone.zone_manager?.name || zone.manager_name}
            />
            <ViewInputData
              label={events.viewEvents.managerEmail}
              content={zone.zone_manager?.email || zone.manager_email}
            />
            <ViewInputData
              label={events.viewEvents.managerPhone}
              content={
                zone.zone_manager?.phone_number
                  ? renderPhoneNumber(
                      zone.zone_manager.dial_code,
                      zone.zone_manager.phone_number
                    )
                  : zone.manager_phone_number
                  ? renderPhoneNumber(
                      zone.manager_phone_dial_code,
                      zone.manager_phone_number
                    )
                  : null
              }
            />
            {event?.status !== "pending" && (
              <>
                <h4 className="mt-5 fsize-18 fweight-600 black-title">
                  {events.viewEvents.supervisorInfo}
                </h4>
                <ViewInputData
                  label={events.viewEvents.supervisorName}
                  content={
                    zone?.supervisor && lang === "en"
                      ? zone?.supervisor.full_name_en
                      : zone?.supervisor && lang === "ar"
                      ? zone?.supervisor.full_name_ar
                      : zoneApplicants.notAssigned
                  }
                />
                <h4 className="mt-5 fsize-18 fweight-600 black-title">
                  {events.viewEvents.applicants}
                </h4>
                <ViewInputData
                  label={events.viewEvents.totalNumberOfApplicants}
                  content={zone?.number_of_organizers}
                />
                <ViewInputData
                  label={events.creatEvent.labels.numberOfMales}
                  content={zone?.number_of_male_organizers}
                />
                <ViewInputData
                  label={events.creatEvent.labels.numberOfFemales}
                  content={zone?.number_of_female_organizers}
                />
                <div className="view-applicants-btn-container">
                  <Button
                    type="button"
                    onClick={() => {
                      history.push(
                        ROUTE_PATHS["zoneApplicants"]
                          .replace(":eventId", event.id)
                          .replace(":zoneId", zone?.id)
                      );
                    }}
                    label={events.viewEvents.viewApplicantsList}
                    labelClass="px-2"
                    className="m-2 fsize-16 fweight-700 btn-delete"
                    outlined
                  />
                </div>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default ViewZonesFields;

ViewZonesFields.propTypes = {
  zone: PropTypes.any,
};
