export const GET_LOOKUPS_REQUEST = "GET_LOOKUPS_REQUEST";
export const GET_LOOKUPS_RESPONSE = "GET_LOOKUPS_RESPONSE";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_RESPONSE = "GET_COUNTRIES_RESPONSE";

export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_RESPONSE = "GET_CITIES_RESPONSE";

export const GET_PROJECT_MANAGERS_REQUEST = "GET_PROJECT_MANAGERS_REQUEST";
export const GET_PROJECT_MANAGERS_RESPONSE = "GET_PROJECT_MANAGERS_RESPONSE";

export const GET_EVENT_ZONES_REQUEST = "GET_EVENT_ZONES_REQUEST";
export const GET_EVENT_ZONES_RESPONSE = "GET_EVENT_ZONES_RESPONSE";
