const applicants = {
  applicantsList: "Applicants List",
  listApplicants: {
    listApplicantsBreadcrumb: "Applicants List",
    name: "Name",
    email: "Email",
    fullName: "Applicant full name",
    profileStatus: "Profile Status",
    verified: "Verified",
    unverified: "Not Verified",
    profile_completed: "Full Profile Completed",
    basic_profile_completed: "Basic Profile Completed",
    applicantStatus: "Applicant Status",
    unblocked: "Unblocked",
    block: "Block",
    assigned: "Assigned",
    notAssigned: "Not Assigned",
    nationality: "Nationality",
    studyField: "Fields of Study",
    profileRate: "Rating",
    role: "Role",
    gender: "Gender",
    notProvided: "Not Provided",
    male: "Male",
    female: "Female",
    prefer_not_to_say: "Not Provided",
    language: "Language",
    age: "Age",
    ageRange1: "16 - 25",
    ageRange2: "25 - 30",
    ageRange3: "30 - 35",
    ageRange4: "35 - 40",
    height: "Height",
    heightRange1: "140 - 160 CM",
    heightRange2: "160 - 180 CM",
    heightRange3: "180 - 200 CM",
    heightRange4: "200 -300 CM",
    weight: "Weight",
    weightRange1: "40 - 50 KG",
    weightRange2: "50 - 70 KG",
    weightRange3: "70 - 100 KG",
    weightRange4: "100 - 120 KG",
    weightRange5: "120 - 170 KG",
    disability: "Disability",
    diseases: "Diseases",
    searchPlaceHolder: "Search applicant's name",
    noDataDescription: "Sorry, No Applicants found.",
    noDisease: "No disease",
    noDisability: "No disability",
    cityOfResidence: "City",
  },
  applicantDetails: {
    applicantDetailsBreadcrumb: "Applicant's Details",
    applicantDetails: "Applicant's Details",
    totalHours: "Total working hours",
    requests: "Requests",
    events: "Events",
    rated: "Rated",
    blockBtn: "Block Applicant",
    blockModalConfirmMessage: "Are you sure you want to block this applicant?",
    unBlockModalConfirmMessage:
      "Are you sure you want to unblock this applicant?",
    pendingRequests: "Show Pending Requests",
    showEvents: "Show Events",
    applicantProfile: {
      labels: {
        basicInfo: {
          title: "Basic Info",
          enName: "Name (EN)",
          arName: "Name (AR)",
          nationalId: "National Id",
          iqamaNumber: "Iqama Number",
          expiryDate: "Expiry Date",
          whatsAppNumber: "Whatsapp Mobile number",
          dateOfBirthHijri: "Date Of Birth(Hijri)",
          dateOfBirthGregorian: "Date Of Birth(Gregorian)",
          matlooobNumber: "Matlooob Number",
          role: "Role",
          gender: "Gender",
          age: "Age",
          height: "Height",
          weight: "Weight",
          disability: "Disability",
          diseases: "Diseases",
          chronicDisease: "Chronic disease",
          nationality: "Nationality",
          cityOfResidence: "City of Residence",
        },
        educationInfo: {
          title: "Education",
          education: "Education Level",
          filedsOfStudy: "Fields of Study",
        },
        languageInfo: {
          title: "Language Skills",
          language: "Language",
          proficiency: "Proficiency",
        },
        documentInfo: {
          title: "Documents",
          certificates: "Certificates",
          academicQualifications: "Academic Qualifications",
          cv: "CV",
          other: "Other",
        },
        bankInfo: {
          title: "Bank Info",
          bankName: "Bank Name",
          IBAN: "IBAN",
          cardHolder: "Card Holder Name",
        },
      },
    },
  },
  applicantRequsets: {
    eventName: "Event Name",
    role: "Role",
    requestDate: "Request Date",
    actions: "Actions",
    accepted: "Accepted",
  },
  applicantEvents: {
    eventName: "Event Name",
    eventStatus: "Event Status",
    role: "Role",
    totalWorkingHours: "Total Working Hours",
    supervisorComments: "Supervisor Comments",
    rating: "Rating",
    notYetProvided: "Not Yet Provided",
    evaluationRounds: "Evaluation Rounds",
    round: "Round ",
    evaluationForm: "Evaluation Form",
  },
  search: "Search by event name",
};

export default applicants;
