import * as types from "./types";

const INITIAL_STATE = {
  requestsList: {
    list: [],
    meta: {},
  },
  zoneApplicants: {
    list: [],
    meta: {},
  },
  applicantsTab: {
    list: [],
    meta: {},
  },
  unassignApplicants: {
    list: [],
    meta: {},
  },
};

const requests = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_REQUESTS_RESPONSE:
      return {
        ...state,
        requestsList: {
          list: action.payload.list,
          meta: action.payload.meta,
        },
      };
    case types.GET_APPLICANTS_TAB_RESPONSE:
      return {
        ...state,
        applicantsTab: {
          list: action.payload.list,
          meta: action.payload.meta,
        },
      };
    case types.GET_UNASSIGN_APPLICANTS_RESPONSE: {
      let arr = action.payload.list.filter((item) => {
        if (
          !state.unassignApplicants.list.find(
            (oldItem) => oldItem.id == item.id
          )
        ) {
          return item;
        }
      });
      return {
        ...state,
        unassignApplicants: {
          list:
            action.payload.meta.currentPage == 1
              ? action.payload.list
              : [...state.unassignApplicants.list, ...arr],
          meta: action.payload.meta,
        },
      };
    }
    case types.GET_ZONE_APPLICANTS_RESPONSE:
      return {
        ...state,
        zoneApplicants: {
          list: action.payload.list,
          meta: action.payload.meta,
        },
      };
    default:
      return state;
  }
};

export default requests;
