import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/ar-sa";

import messages from "../assets/locale/messages";
import store from "../store";
import { showHideSnackbar } from "../store/Snackbar/actions";
import Tooltip from "../components/Tooltip";

export const showSuccessMsg = (msg, showMsgDirectly) => {
  const lang = store.getState().locale.lang;
  const { successMsgs } = messages[lang];
  store.dispatch(
    showHideSnackbar({
      isOpen: true,
      type: "success",
      message: showMsgDirectly ? msg : successMsgs[msg],
    })
  );
};

export const showErrorMsg = (message) => {
  store.dispatch(
    showHideSnackbar({
      isOpen: true,
      type: "error",
      message,
    })
  );
};

export const renderTextEllipsis = (text = "", length = 30) => {
  if (text?.length > length) {
    const trimmedText = text.slice(0, length);
    return <Tooltip title={text} content={<span>{trimmedText}...</span>} />;
  } else {
    return text;
  }
};

export const formatDate = (date, format = "DD-MM-YYYY") =>
  dayjs(date).format(format);

export const addZeroToSingleNumber = (number) => {
  return String(number).padStart(2, "0");
};

export const getNamesInitials = (fullName) => {
  return fullName
    ? fullName
        .split(" ")
        .filter((_, i) => i < 2)
        .map((name) => name[0]?.toUpperCase())
        .join("")
    : "";
};

export const getFileName = (fileName) => {
  // let startIndex = fileName.indexOf("/files/") + "/files/".length;
  // return fileName.slice(startIndex);
  return fileName.replace(/^.*[\\/]/, "");
};

export const convertHtmlToText = (htmlString) =>
  htmlString ? htmlString.replace(/<[^>]+>/g, "") : htmlString;

export const textContainOnlySpaces = (text) =>
  text.length && /^\s*$/.test(text);

export const nonEmptyObject = (obj) => {
  let copyObj = { ...obj };
  for (const key in copyObj) {
    if (copyObj[key] === undefined || copyObj[key] === "") {
      copyObj[key] = null;
    }
  }
  return JSON.parse(JSON.stringify(copyObj));
};

export const generateUniqueSrc = (src) => {
  const timestamp = new Date().getTime();
  return `${src}?v=${timestamp}`;
};

// TODO: remove this temp fix afer dayjs issue is solved
export const checkFor12amIssue = (time) => {
  const hour11 = dayjs("11:59", "HH:mm");
  const hour13 = dayjs("13:00", "HH:mm");
  const timeObj = dayjs(time);
  let localizedTime = dayjs(time).locale("ar-sa").format("hh:mm a");
  if (timeObj.isBetween(hour11, hour13, null, "[]")) {
    const correctLocalizedTime = localizedTime.replace("ص", "م");
    return correctLocalizedTime;
  }
  return localizedTime;
};
