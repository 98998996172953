import { axiosInstance } from "./";

const getApplicantsList = async (params) =>
  await axiosInstance.get("/users", { params });

const updateApplicant = async ({ id, params }) =>
  await axiosInstance.patch(`/users/${+id}`, params);

const getApplicant = async (id) => await axiosInstance.get(`/users/${id}`);

const getEligibleApplicantsList = async ({ id, params }) =>
  await axiosInstance.get(`/events/${id}/eligible_users`, { params });

export {
  getApplicantsList,
  getApplicant,
  updateApplicant,
  getEligibleApplicantsList,
};
