export const GET_APPLICANTS_REQUEST = "GET_APPLICANTS_REQUEST";
export const GET_APPLICANTS_RESPONSE = "GET_APPLICANTS_RESPONSE";

export const GET_APPLICANT_REQUEST = "GET_APPLICANT_REQUEST";
export const GET_APPLICANT_RESPONSE = "GET_APPLICANT_RESPONSE";

export const UPDATE_APPLICANT = "UPDATE_APPLICANT";

export const GET_ELIGIBLE_APPLICANTS_REQUEST = "GET_ELIGIBLE_APPLICANTS_REQUEST";
export const GET_ELIGIBLE_APPLICANTS_RESPONSE = "GET_ELIGIBLE_APPLICANTS_RESPONSE";
