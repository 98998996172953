const requests = {
  listRequests: {
    list: "قائمه طلبات الفعاليات",
    search: "ابحث باستخدام اسم المتقدم او اسم الفعاليه",
    status: "حاله",
    eventName: "اسم الفعالية",
    applicantName: "اسم المتقدم",
    date: "التاريخ",
    rating: "تقييم",
    all: "الكل",
    approved: "مقبول",
    pending: "قيد الانتظار",
    cancelled: "ملغي",
    auto_cancelled: "ملغي تلقائيا",
    placeholder: "لا يوجد طلبات حاليا ",
    warningMessage:
      "الحد الأقصى  لعدد الجنس المحدد  إكتمل ، هل انت متاكد من المتابعة ؟",
    warningTitle: "موافقه على تنبيه",
    cityOfResidence: "المدينة",
    changeRoleTitle: "تغيير الوظيفة ل",
  },
};

export default requests;
