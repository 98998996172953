const shared = {
  home: "Home",
  events: "Events",
  employees: "Employees",
  requests: "Requests",
  joiningEvents: "Joining Events",
  promotions: "Promotions",
  applicants: "Applicants",
  timesheet: "Timesheet",
  evaluations: "Evaluations",
  language: "Ar",
  englishLang: "English (united States)",
  arabicLang: "Arabic (saudi arabia)",
  creatEvent: "Create Event",
  admins: "Admins",
  addAdmin: "Add admins",
  lookups: "Lookups",
  serviceProviderCategories: "Service provider categories",
  serviceProviders: "Service providers",
  specialties: "Specialties",
  services: "Services",
  logout: "Logout",
  lang: "العربية",
  back: "Back",
  search: "Search for an employee",
  noSearch: "There are no results matching your search",
  noData: "There are no data yet",
  actions: "Actions",
  edit: "Edit",
  view: "View",
  delete: "Delete",
  save: "Save",
  submit: "Submit",
  cancel: "Cancel",
  filter: "Filter",
  confirm: "Confirm",
  approve: "Approve",
  block: "Block",
  blocked: "Blocked",
  unBlock: "Unblock",
  none: "None",
  id: "ID",
  viewMore: "View More",
  lists: {
    enTitle: "Title (En)",
    arTitle: "Title (Ar)",
    enName: "Name (En)",
    arName: "Name (Ar)",
    name: "Name",
    email: "Email",
    phone: "Phone number",
    image: "Image",
    categories: "Categories",
    status: "Status",
  },
  filesAccepted: "Max size: 5MB, accepted formats: jpg, jpeg, png, and bmp",
  uploadTypeError: "Accepted formats are jpg, jpeg, png, and bmp only",
  uploadSizeError: "Max size is 5MB",
  acceptedFormatLabel: "Accepted format",
  noOptions: "No options",
  pdfUploadTypeError: "Accepted format is PDF only",
  pdfUploadSizeError: "Max size is 5MB",
  dynamicTableActions: {
    columnMenuShowColumns: "Show columns",
    columnsPanelTextFieldLabel: "Find column",
    columnsPanelTextFieldPlaceholder: "Column title",
    columnsPanelShowAllButton: "Show all",
    columnsPanelHideAllButton: "Hide all",
    columnMenuLabel: "Menu",
  },
  role: "Role",
  gender: "Gender",
  male: "Male",
  female: "Female",
  notProvided: "Not Provided",
  noInternetConnection: "No Internet Connection",
  generalErrMsg: "Sorry, something went wrong!",
  cm: "Cm",
  kg: "Kg",
  genderOptions: {
    male: "Male",
    female: "Female",
    prefer_not_to_say: "Not Provided",
  },
  status: "Status",
  image: "Image",
  eventStatusOptions: {
    ongoing: "Ongoing",
    upcoming: "Upcoming",
    past: "Completed",
    pending: "Pending",
    canceled: "Cancelled",
  },
  hint: "To change the role before approving the request, edit the dropdown selection",
  sar: "SAR",
  other: "Other",
  meter: "meters",
  minutes: "minutes",
  select: "Select",
  numberOfRows: "Number Of Rows",
  invite: "Invite",
  selected: "Selected",
  outOf: "Out Of",
  all: "All",
};

export default shared;
