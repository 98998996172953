import * as types from "./types";

// list
export const getRequestsRequest = (payload) => ({
  type: types.GET_REQUESTS_REQUEST,
  payload,
});

export const getRequestsResponse = (payload) => ({
  type: types.GET_REQUESTS_RESPONSE,
  payload,
});

export const getApplicantsTabResponse = (payload) => ({
  type: types.GET_APPLICANTS_TAB_RESPONSE,
  payload,
});

export const getUnassignApplicantsResponse = (payload) => ({
  type: types.GET_UNASSIGN_APPLICANTS_RESPONSE,
  payload,
});

export const getzoneApplicantsResponse = (payload) => ({
  type: types.GET_ZONE_APPLICANTS_RESPONSE,
  payload,
});

export const approveRequest = (payload) => ({
  type: types.APPROVE_REQUEST,
  payload,
});

export const updateEventRequest = (payload) => ({
  type: types.UPDATE_EVENT_REQUEST,
  payload,
});

export const updateSupervisorEventRequest = (payload) => ({
  type: types.UPDATE_SUPERVISOR_EVENT_REQUEST,
  payload,
});

export const bulkAssign = (payload) => ({
  type: types.BULK_ASSIGN,
  payload,
});