import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { TableCell, TableRow, Chip, Alert } from "@mui/material";
import { Star, BorderColorOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

import Table from "../Table";
import Button from "../Button";
import Breadcrumbs from "../BreadCrumbs";
import Pagination from "../Pagination";
import Filters from "../Filter";
import EmptyState from "../EmptyState";
import Modal from "../Modal";
import Rate from "../Rate";
import RadioButtonsGroup from "../RadioButton";
import Avatar from "../Avatar";
import Checkbox from "../Checkbox";
import Select from "../Select";
import {
  renderTextEllipsis,
  formatDate,
  textContainOnlySpaces,
} from "../../utils/Helpers";
import {
  getRequestsRequest,
  approveRequest,
} from "../../store/Requests/actions";
import { getEligibleApplicantsRequest } from "../../store/applicants/actions";
import {
  getCitiesRequest,
  getLookupsRequest,
} from "../../store/Lookups/actions";
import { inviteApplicantsRequest } from "../../store/Events/actions";
import { lookupsKeys, requestStatuses, genders } from "../../utils/Constants";
import messages from "../../assets/locale/messages";
import requestPlaceholder from "../../assets/images/request-screen/request-placeholder.svg";
import applicantPlaceholder from "../../assets/images/applicant-screen/applicants-placeholder.svg";
import { ROUTE_PATHS } from "../../utils/RoutesPaths";
import "./RequestsTable.scss";

const RequestsTable = ({
  isApplicant,
  isInviteApplicants,
  headlines,
  breadcrumbsList,
  userId,
  searchPlaceholder,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const lang = useSelector((state) => state.locale.lang);
  const { requests, shared, events, applicants } = messages[lang];

  const meta = useSelector((state) =>
    isInviteApplicants
      ? state.applicants.eligibleApplicants.meta
      : state.requests.requestsList.meta
  );
  const list = useSelector((state) =>
    isInviteApplicants
      ? state.applicants.eligibleApplicants.list
      : state.requests.requestsList.list
  );
  const roles = useSelector((state) => state.lookups.general.roles);
  const cities = useSelector((state) => state.lookups.cities);
  const isLoading = useSelector((state) => state.loader);

  const [rowsCount, setRowsCount] = useState(10);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [openWarningApproveModal, setOpenWarningApproveModal] = useState(false);
  const [filterGender, setFilterGender] = useState(null);
  const [filterRequestStatus, setFilterRequestStatus] = useState({
    id: 3,
    name: requests.listRequests.pending,
    value: requestStatuses.notAccepted,
  });
  const [filterRoles, setFilterRoles] = useState(null);
  const [filterRating, setFilterRating] = useState(null);
  const [filterCities, setFilterCities] = useState(null);
  const [singleRole, setSingleRole] = useState(null);
  const [requestId, setRequestId] = useState(null);

  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [userName, setUserName] = useState(null);
  const [activeUserId, setActiveUserId] = useState(null);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [originalRole, setOriginalRole] = useState(null);
  const [roleIndex, setRoleIndex] = useState(null);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [autoSelect, setAutoSelect] = useState(false);

  const rowsCountOptions = [
    { id: 10, name: 10 },
    { id: 20, name: 20 },
    { id: 30, name: 30 },
  ];

  const statusOptions = [
    { id: 1, name: requests.listRequests.all, value: null },
    {
      id: 2,
      name: requests.listRequests.approved,
      value: requestStatuses.accepted,
    },
    {
      id: 3,
      name: requests.listRequests.pending,
      value: requestStatuses.notAccepted,
    },
    {
      id: 4,
      name: requests.listRequests.cancelled,
      value: requestStatuses.cancelled,
    },
    {
      id: 5,
      name: requests.listRequests.auto_cancelled,
      value: requestStatuses.autoCancelled,
    },
  ];
  const genderOptions = [
    {
      id: 1,
      name: shared.male,
      value: genders.male,
    },
    {
      id: 2,
      name: shared.female,
      value: genders.female,
    },
    {
      id: 3,
      name: shared.notProvided,
      value: genders.notProvided,
    },
  ];
  const ratingOptions = [
    {
      id: 1,
      name: "1",
      value: 1,
      icon: <Star className="rating-star-icon" />,
    },
    {
      id: 2,
      name: "2",
      value: 2,
      icon: <Star className="rating-star-icon" />,
    },
    {
      id: 3,
      name: "3",
      value: 3,
      icon: <Star className="rating-star-icon" />,
    },
    {
      id: 4,
      name: "4",
      value: 4,
      icon: <Star className="rating-star-icon" />,
    },
    {
      id: 5,
      name: "5",
      value: 5,
      icon: <Star className="rating-star-icon" />,
    },
  ];

  useEffect(() => {
    const allRoles = list?.map((applicant) => {
      const previouslySelectedApplicant = selectedApplicants.find(
        (selected) => selected.user_id === applicant.id
      );
      return isInviteApplicants && previouslySelectedApplicant
        ? previouslySelectedApplicant.role
        : applicant.role;
    });
    setSelectedRoles(allRoles);
  }, [!!list?.length, list]);

  const approveRequestHandler = (
    selectedRole,
    requestId,
    acceptableRoles,
    gender
  ) => {
    if (
      (acceptableRoles[0]?.maximum_number_of_females <=
        acceptableRoles[0]?.number_of_approved_female_requests &&
        gender == genders.female) ||
      (acceptableRoles[0]?.maximum_number_of_males <=
        acceptableRoles[0]?.number_of_approved_male_requests &&
        gender == genders.male) ||
      (acceptableRoles[0]?.maximum_number_of_males <=
        acceptableRoles[0]?.number_of_approved_male_requests &&
        acceptableRoles[0]?.maximum_number_of_females <=
          acceptableRoles[0]?.number_of_approved_female_requests &&
        gender == genders.notProvided)
    ) {
      setOpenWarningApproveModal(true);
      setRequestId(requestId);
      setSingleRole(selectedRole);
    } else {
      onApproveRequest(selectedRole, requestId);
    }
  };

  const onApproveRequest = (selectedRole, requestId) => {
    let params = {
      role_id: selectedRole.id,
    };
    dispatch(
      approveRequest({
        data: params,
        id: requestId,
        listParams: isApplicant
          ? {
              page: page,
              items: 10,
              user_id: userId,
              event_name: searchValue,
              status: "pending",
              role_id: filterRoles?.id,
              order_by_created_at: "desc",
              responseType: "requests",
            }
          : {
              page: page,
              items: 10,
              event_and_user_name: searchValue,
              status: filterRequestStatus.value,
              role_id: filterRoles?.id,
              min_user_rating: filterRating?.value,
              max_user_rating: filterRating?.value
                ? filterRating?.value + 0.99
                : null,
              order_by_created_at: "asc",
              responseType: "requests",
              city_id: filterCities?.id,
            },
      })
    );
    handleCloseWarningApproveModal();
  };

  const handleCloseWarningApproveModal = () => {
    setOpenWarningApproveModal(false);
  };

  const getRequests = (
    pageNo,
    search,
    requestStatus,
    role,
    gender,
    rating,
    city
  ) => {
    dispatch(
      isInviteApplicants
        ? getEligibleApplicantsRequest({
            id,
            params: {
              page: pageNo,
              items: rowsCount,
              name: search,
              gender: gender?.value,
              city_id: city?.id,
              role_id: role?.id,
              min_rating: rating?.value,
              max_rating: rating?.value ? rating?.value + 0.99 : null,
            },
          })
        : getRequestsRequest(
            isApplicant
              ? {
                  page: pageNo,
                  items: 10,
                  user_id: userId,
                  event_name: search,
                  status: "pending",
                  role_id: role?.id,
                  order_by_created_at: "desc",
                  responseType: "requests",
                }
              : {
                  page: pageNo,
                  items: 10,
                  event_and_user_name: search,
                  status: requestStatus?.value,
                  role_id: role?.id,
                  gender: gender?.value,
                  order_by_created_at: "asc",
                  responseType: "requests",
                  min_user_rating: rating?.value,
                  max_user_rating: rating?.value ? rating?.value + 0.99 : null,
                  city_id: city?.id,
                }
          )
    );
  };

  useEffect(() => {
    if (
      (searchValue.length >= 3 || searchValue.length === 0) &&
      !textContainOnlySpaces(searchValue)
    ) {
      setPage(1);
      getRequests(
        1,
        searchValue,
        filterRequestStatus,
        filterRoles,
        filterGender,
        filterRating,
        filterCities
      );
    }
  }, [searchValue]);

  useEffect(() => {
    setPage(1);
    getRequests(
      1,
      searchValue,
      filterRequestStatus,
      filterRoles,
      filterGender,
      filterRating,
      filterCities
    );
  }, [
    filterRequestStatus,
    filterGender,
    filterCities,
    filterRating,
    filterRoles,
    rowsCount,
  ]);

  useEffect(() => {
    getRequests(
      page,
      searchValue,
      filterRequestStatus,
      filterRoles,
      filterGender,
      filterRating,
      filterCities
    );
  }, [page]);

  useEffect(() => {
    dispatch(getLookupsRequest([lookupsKeys.roles]));
    dispatch(
      getCitiesRequest({
        page: -1,
        order_by_name_en: lang === "en" ? "asc" : null,
        order_by_name_ar: lang === "ar" ? "asc" : null,
      })
    );

    getRequests(
      page,
      searchValue,
      filterRequestStatus,
      filterRoles,
      filterGender,
      filterRating,
      filterCities
    );

    if (filterRequestStatus) {
      const localizedFilterValue = statusOptions.find(
        (option) => option.id === filterRequestStatus.id
      );
      setFilterRequestStatus(localizedFilterValue);
    }

    if (filterGender) {
      const localizedFilterValue = genderOptions.find(
        (option) => option.id === filterGender.id
      );
      setFilterGender(localizedFilterValue);
    }
  }, [lang]);

  useEffect(() => {
    if (filterCities) {
      const localizedFilterValue = cities.find(
        (option) => option.id === filterCities.id
      );
      setFilterCities(localizedFilterValue);
    }
  }, [lang, cities]);

  useEffect(() => {
    if (filterRoles) {
      const localizedFilterValue = roles.find(
        (option) => option.id === filterRoles.id
      );
      setFilterRoles(localizedFilterValue);
    }
  }, [lang, roles]);

  const onEditRole = ({
    options,
    currentRole,
    applicantId,
    index,
    applicantName,
  }) => {
    setRolesOptions(options);
    setOriginalRole(currentRole);
    setRoleIndex(index);
    setUserName(applicantName);
    setActiveUserId(applicantId);
    setOpenRoleModal(true);
  };

  const handleSelectRole = (value, index) => {
    if (value) {
      const selectedRolesCopy = [...selectedRoles];
      selectedRolesCopy[index] = value;
      setSelectedRoles(selectedRolesCopy);
    }
  };

  const handleCloseRoleModal = (selectedRole) => {
    handleSelectRole(selectedRole || originalRole, roleIndex);
    setOpenRoleModal(false);
    setUserName(null);
    setActiveUserId(null);
    setOriginalRole(null);
    setRolesOptions([]);
    setRoleIndex(null);
    setIsRoleSelected(false);
    setAutoSelect(false);
  };
  const renderRolesOptions = () => {
    const radioList = rolesOptions?.map((option) => ({
      value: option?.id,
      label: option?.name,
    }));
    const currentRole = selectedRoles?.[roleIndex]?.id;
    const selectedRole = radioList.find(
      (item) => item.value == selectedRoles?.[roleIndex]?.id
    );
    if (isRoleSelected != !!selectedRole) setIsRoleSelected(!!selectedRole);
    return (
      <RadioButtonsGroup
        id="user-role"
        name="user-role"
        handleChange={(e) => {
          const selectedRole = rolesOptions.find(
            (elt) => elt.id == e.target.value
          );
          handleSelectRole(selectedRole, roleIndex);
        }}
        value={currentRole}
        listOfRadios={radioList}
      />
    );
  };

  const handleConfirmRole = () => {
    const requestData = list[roleIndex];
    if (isInviteApplicants) {
      handleChangeSelectedApplicantRole();
      handleCloseRoleModal(selectedRoles[roleIndex]);
    } else {
      approveRequestHandler(
        selectedRoles[roleIndex],
        requestData.id,
        requestData.acceptable_roles,
        requestData.user?.gender
      );
      handleCloseRoleModal();
    }
  };

  const handleChangeSelectedApplicantRole = () => {
    const selectedApplicant = selectedApplicants.find(
      (applicant) => applicant.user_id === activeUserId
    );
    const selectedApplicantIndex = selectedApplicants.findIndex(
      (applicant) => applicant.user_id === activeUserId
    );
    if (selectedApplicant) {
      const copySelectedApplicantsList = [...selectedApplicants];
      copySelectedApplicantsList.splice(selectedApplicantIndex, 1, {
        ...selectedApplicant,
        role: selectedRoles[roleIndex],
      });
      setSelectedApplicants(copySelectedApplicantsList);
    } else {
      if (autoSelect) handleSelectApplicant(true, activeUserId, roleIndex);
    }
  };

  const handleSelectApplicant = (isSelected, applicantId, applicantIndex) => {
    if (isSelected) {
      const applicantSelectedRole = selectedRoles[applicantIndex];
      setSelectedApplicants([
        ...selectedApplicants,
        {
          user_id: applicantId,
          role: applicantSelectedRole,
        },
      ]);
    } else {
      setSelectedApplicants(
        selectedApplicants.filter(
          (applicant) => applicant.user_id !== applicantId
        )
      );
    }
  };

  const handleInviteApplicants = () => {
    const invitations = selectedApplicants
      .filter(({ role }) => role)
      .map(({ user_id, role }) => ({
        user_id,
        role_id: role.id,
      }));
    dispatch(
      inviteApplicantsRequest({
        event_id: +id,
        invitations,
      })
    );
  };

  const renderRequestsList = () => {
    if (!isLoading && !list?.length) {
      return (
        <>
          <div className="bg-white rounded-4 mt-4 pb-3">
            <EmptyState
              description={
                isInviteApplicants
                  ? applicants.listApplicants.noDataDescription
                  : requests.listRequests.placeholder
              }
              imgSrc={
                isInviteApplicants ? applicantPlaceholder : requestPlaceholder
              }
              showActionButton={false}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          {isInviteApplicants && (
            <div className="selected-applicants-count text-end">
              <p className="mb-0">
                <span
                  className={`text-light-grey ${
                    lang === "en" ? "me-2" : "ms-2"
                  }`}
                >
                  {shared.selected}
                </span>
                <span
                  className={`${
                    selectedApplicants.length > 50
                      ? "text-warning"
                      : "text-primary-blue"
                  } fweight-500`}
                >
                  {selectedApplicants.length}
                </span>
                <span className="text-light-grey mx-2">{shared.outOf}</span>
                <span className="text-primary-blue fweight-500">{50}</span>
              </p>
            </div>
          )}
          <div className="bg-white rounded-4 mt-2 pb-3">
            <Table
              className="rounded-4"
              headlines={headlines}
              hasActions={false}
              rows={list?.map((row, i) => {
                const acceptableRoles = row.acceptable_roles;
                const currentRole = selectedRoles[i];
                const isRoleAcceptable = !!acceptableRoles.find(
                  (role) => role?.id == currentRole?.id
                );
                return (
                  <TableRow key={i}>
                    {isInviteApplicants && (
                      <TableCell className="select-column">
                        <Checkbox
                          name={row.id}
                          id={row.id}
                          checked={
                            !!selectedApplicants.find(
                              (applicant) => applicant.user_id === row.id
                            )
                          }
                          onChange={(isChecked) => {
                            if (isChecked && !isRoleAcceptable) {
                              setAutoSelect(true);
                              onEditRole({
                                options: row.acceptable_roles,
                                currentRole: selectedRoles[i],
                                index: i,
                                applicantId: row.id,
                                applicantName:
                                  row[
                                    lang === "en"
                                      ? "full_name_en"
                                      : "full_name_ar"
                                  ],
                              });
                            } else {
                              handleSelectApplicant(isChecked, row.id, i);
                            }
                          }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        />
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell className="avatar-column">
                        <Avatar
                          imgSrc={
                            isInviteApplicants ? row.image : row.user?.image
                          }
                          imgSrcAlt="user-picture"
                          size={30}
                          name={
                            isInviteApplicants
                              ? row.full_name_en
                              : row.user?.full_name_en
                          }
                        />
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell
                        className={`${
                          (isInviteApplicants
                            ? !row.deleted_at
                            : !row.user?.deleted_at) && "pointer"
                        } mobile-style name-cell`}
                        onClick={() => {
                          if (
                            isInviteApplicants
                              ? !row.deleted_at
                              : !row.user?.deleted_at
                          ) {
                            history.push(
                              ROUTE_PATHS.viewApplicant.replace(
                                ":id",
                                isInviteApplicants ? row.id : row.user?.id
                              )
                            );
                          }
                        }}
                      >
                        {(
                          isInviteApplicants
                            ? row["full_name_" + lang]
                            : row.user?.["full_name_" + lang]
                        )
                          ? renderTextEllipsis(
                              isInviteApplicants
                                ? row["full_name_" + lang]
                                : row.user?.["full_name_" + lang]
                            )
                          : "-"}
                      </TableCell>
                    )}
                    {!isInviteApplicants && (
                      <TableCell className="pointer mobile-style event-cell">
                        <Link
                          onClick={() =>
                            history.push(
                              ROUTE_PATHS.viewEvent.replace(
                                ":id",
                                row.event?.id
                              )
                            )
                          }
                        >
                          {row.event?.name
                            ? renderTextEllipsis(row.event?.name)
                            : "-"}
                        </Link>
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell className="mobile-style city-cell">
                        {(isInviteApplicants
                          ? row.city?.name
                          : row.user?.city?.name) || "-"}
                      </TableCell>
                    )}
                    {!isApplicant && (
                      <TableCell className="mobile-style">
                        {(isInviteApplicants ? row.gender : row.user.gender)
                          ? shared.genderOptions[
                              isInviteApplicants ? row.gender : row.user.gender
                            ]
                          : "-"}
                      </TableCell>
                    )}
                    <TableCell className="mobile-style role-cell">
                      {(
                        isInviteApplicants
                          ? row.role?.name_en !== "Supervisor"
                          : row.status === "pending" &&
                            row.role?.name_en !== "Supervisor"
                      )
                        ? selectedRoles[i] && (
                            <div className="d-flex align-items-center justify-content-between edit-role-wrapper">
                              <span>{selectedRoles[i]?.name}</span>
                              <BorderColorOutlined
                                className="mx-2 pointer"
                                color={ isInviteApplicants 
                                  ? (isRoleAcceptable
                                  ? "primary" 
                                  : "error")
                                  : "primary"
                              }
                                fontSize="14px"
                                onClick={() =>
                                  onEditRole({
                                    options: row.acceptable_roles,
                                    currentRole: selectedRoles[i],
                                    index: i,
                                    applicantId: isInviteApplicants
                                      ? row.id
                                      : row.user?.id,
                                    applicantName: isInviteApplicants
                                      ? row[
                                          lang === "en"
                                            ? "full_name_en"
                                            : "full_name_ar"
                                        ]
                                      : row.user?.[
                                          lang === "en"
                                            ? "full_name_en"
                                            : "full_name_ar"
                                        ],
                                  })
                                }
                              />
                            </div>
                          )
                        : row.role?.name}
                    </TableCell>
                    {!isApplicant && (
                      <TableCell>
                        {(
                          isInviteApplicants ? row.rating : row.user?.rating
                        ) ? (
                          <div className="MuiDataGrid-cellContent d-flex">
                            <Rate
                              rating={
                                isInviteApplicants
                                  ? row.rating
                                  : row.user?.rating.toFixed(2)
                              }
                            />
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    )}
                    {!isInviteApplicants && (
                      <TableCell className="mobile-style date-cell">
                        {formatDate(row.created_at)}
                      </TableCell>
                    )}
                    {!isApplicant && !isInviteApplicants && (
                      <TableCell>
                        <Chip
                          label={requests.listRequests[row.status]}
                          className={`fsize-14 fweight-500 text-white status-container ${row.status}`}
                        />
                      </TableCell>
                    )}
                    {!isInviteApplicants && (
                      <TableCell>
                        <Button
                          label={shared.approve}
                          className="request-action"
                          labelClass={`fsize-14 fweight-500 text-white`}
                          block
                          onClick={() => {
                            approveRequestHandler(
                              selectedRoles[i],
                              row.id,
                              row.acceptable_roles,
                              row.user?.gender
                            );
                          }}
                          disabled={row.status !== "pending"}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            />
          </div>
          <div className="row">
            <div className="col-md-2 col-xl-4"></div>
            <div className="col-md-6 col-xl-5 mt-3">
              <div
                className={`d-flex justify-content-center ${
                  isInviteApplicants ? "" : "py-3 mt-3"
                }`}
              >
                {meta.pages > 1 && (
                  <Pagination
                    count={meta.pages}
                    page={meta.currentPage || page}
                    handleChange={(event, page) => {
                      setPage(page);
                    }}
                    defaultPage={1}
                  />
                )}
              </div>
            </div>
            <div className="col-md-4 col-xl-3 mt-3">
              {isInviteApplicants && (
                <div className="rows-count-selection">
                  <Select
                    name="rows-count"
                    label={shared.numberOfRows}
                    value={rowsCountOptions.find(
                      (count) => count.id === rowsCount
                    )}
                    onChange={(count) => {
                      setRowsCount(count.id);
                    }}
                    options={rowsCountOptions}
                    wrapperClass={`rows-count-input`}
                    disableClearable={true}
                  />
                </div>
              )}
            </div>
          </div>

          {isInviteApplicants && (
            <div className="d-flex justify-content-end my-3">
              <div className="d-flex justify-content-end align-items-center invite-btns-container">
                <Button
                  type="button"
                  block
                  label={shared.invite}
                  labelClass="px-4"
                  disabled={
                    selectedApplicants.length > 50 ||
                    selectedApplicants.length === 0
                  }
                  className="mx-2 continue-btn"
                  onClick={handleInviteApplicants}
                />
                <Button
                  onClick={() => {
                    history.push(ROUTE_PATHS.viewEvent.replace(":id", id));
                  }}
                  type="button"
                  block
                  label={shared.cancel}
                  labelClass="px-4"
                  outlined
                  className="mx-2 cancel-btn"
                />
              </div>
            </div>
          )}
          {openRoleModal && (
            <Modal
              open={openRoleModal}
              handleClose={() => handleCloseRoleModal()}
              title={`${requests.listRequests.changeRoleTitle} ${userName}`}
              content={renderRolesOptions()}
              isContentText={false}
              confirmBtnTxt={isInviteApplicants ? shared.save : shared.approve}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              handleCancel={() => handleCloseRoleModal()}
              handleConfirm={handleConfirmRole}
              modalClass="role-select-modal"
              isDisabled={!isRoleSelected}
            />
          )}
          {openWarningApproveModal && (
            <Modal
              open={openWarningApproveModal}
              handleClose={handleCloseWarningApproveModal}
              title={requests.listRequests.warningTitle}
              content={
                <Alert severity="warning" className="fsize-16">
                  {requests.listRequests.warningMessage}
                </Alert>
              }
              isContentText={false}
              confirmBtnTxt={shared.approve}
              maxWidth="xs"
              cancelBtnTxt={shared.cancel}
              handleConfirm={() => onApproveRequest(singleRole, requestId)}
              handleCancel={handleCloseWarningApproveModal}
              modalClass="warning-modal"
              actionsClass="container"
              confirmBtnClass="btn-secondary text-white"
            />
          )}
        </>
      );
    }
  };

  return (
    <div
      className={`conatiner requests-list-container ${
        isInviteApplicants && "invite-applicants-list-container"
      }`}
    >
      <div className="page-title-margin">
        <Breadcrumbs list={breadcrumbsList} BreadCrumbHasBackIcon={false} />
      </div>
      <Filters
        searchInput={{
          placeholder: searchPlaceholder,
          name: "search",
          value: searchValue,
          onChange: (value) => setSearchValue(value),
        }}
        filters={
          isApplicant
            ? [
                {
                  type: "filter",
                  name: "role",
                  placeholder: shared.role,
                  value: filterRoles,
                  options: roles,
                  onChange: (value) => setFilterRoles(value),
                },
              ]
            : isInviteApplicants
            ? [
                {
                  type: "filter",
                  name: "rating",
                  placeholder: requests.listRequests.rating,
                  value: filterRating,
                  options: ratingOptions,
                  isOptionsElt: true,
                  onChange: (value) => setFilterRating(value),
                },
                {
                  type: "filter",
                  name: "gender",
                  placeholder: events.viewEvents.applicantsTab.gender,
                  value: filterGender,
                  options: genderOptions,
                  onChange: (value) => setFilterGender(value),
                },
                {
                  type: "filter",
                  name: "role",
                  placeholder: shared.role,
                  value: filterRoles,
                  options: roles,
                  onChange: (value) => setFilterRoles(value),
                },
                {
                  type: "filter",
                  name: "city",
                  placeholder: requests.listRequests.cityOfResidence,
                  value: filterCities,
                  options: cities,
                  onChange: (value) => setFilterCities(value),
                },
              ]
            : [
                {
                  type: "filter",
                  name: "status",
                  placeholder: requests.listRequests.status,
                  value: filterRequestStatus,
                  options: statusOptions,
                  onChange: (value) => setFilterRequestStatus(value),
                },
                {
                  type: "filter",
                  name: "rating",
                  placeholder: requests.listRequests.rating,
                  value: filterRating,
                  options: ratingOptions,
                  isOptionsElt: true,
                  onChange: (value) => setFilterRating(value),
                },
                {
                  type: "filter",
                  name: "gender",
                  placeholder: events.viewEvents.applicantsTab.gender,
                  value: filterGender,
                  options: genderOptions,
                  onChange: (value) => setFilterGender(value),
                },
                {
                  type: "filter",
                  name: "role",
                  placeholder: shared.role,
                  value: filterRoles,
                  options: roles,
                  onChange: (value) => setFilterRoles(value),
                },
                {
                  type: "filter",
                  name: "city",
                  placeholder: requests.listRequests.cityOfResidence,
                  value: filterCities,
                  options: cities,
                  onChange: (value) => setFilterCities(value),
                },
              ]
        }
      />
      <div className="d-flex flex-column justify-content-between">
        {renderRequestsList()}
      </div>
    </div>
  );
};

RequestsTable.propTypes = {
  isApplicant: PropTypes.bool,
  isInviteApplicants: PropTypes.bool,
  headlines: PropTypes.array,
  breadcrumbsList: PropTypes.array,
  userId: PropTypes.number,
  searchPlaceholder: PropTypes.string,
};

RequestsTable.defaultProps = { isApplicant: false };

export default RequestsTable;
