export const acceptedImagesFilesTypes = [
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/jpg",
];

export const acceptedImagesFilesMaxSize = 5242880;

export const acceptedPdfFilesType = ["application/pdf"];

export const acceptedPdfFilesMaxSize = 5242880;

export const lookupsKeys = {
  adminRoles: "admin_roles",
  requirements: "requirements",
  roles: "roles",
  languages: "languages",
  disabilities: "disabilities",
  chronicDiseases: "chronic_diseases",
  fieldOfStudies: "field_of_studies",
  banks: "banks",
  workingLogsAttributes: "working_log",
};
export const reversedLookupsKeys = {
  admin_roles: "adminRoles",
  requirements: "requirements",
  roles: "roles",
  languages: "languages",
  disabilities: "disabilities",
  chronic_diseases: "chronicDiseases",
  field_of_studies: "fieldOfStudies",
  banks: "banks",
  working_log: "workingLogsAttributes",
};

export const eventStatuses = {
  ongoing: "ongoing",
  upcoming: "upcoming",
  past: "past",
  pending: "pending",
  cancelled: "canceled",
};

export const requestStatuses = {
  accepted: "approved",
  notAccepted: "pending",
  cancelled: "cancelled",
  autoCancelled: "auto_cancelled",
};
export const promotionRequestsStatus = {
  accepted: "approved",
  notAccepted: "pending",
  rejected: "rejected",
};

export const evaluationFormStatus = {
  pending: "pending",
  submitted: "submitted",
};

export const workingLogsColumns = {
  // daily events
  dailyNameAr: "user_full_name_ar",
  dailyNameEn: "user_full_name_en",
  dailyRole: "role_name",
  dailyIdentificationId: "user_identification_id",
  dailyNationality: "nationality_name",
  dailyPhoneNumber: "user_phone_number",
  dailyEmail: "user_email",
  dailyDobHijri: "user_hijri_date_of_birth",
  dailyNumberOfDays: "number_of_days",
  dailyWorkingHours: "working_hours",
  dailyExpectedPayroll: "expected_payroll",
  dailyTotalExpectedPayroll: "total_expected_payroll",
  dailyIban: "user_iban",
  dailySignature: "signature",
  // Monthly events
  monthlyDate: "readable_date",
  monthlyApplicantName: "user_name",
  monthlyRole: "role_name",
  monthlyWorkingHours: "working_hours",
  monthlyExpectedTotalPayroll: "total_expected_payroll",
};

export const eventCheckinVerificationMethodsLocalization = {
  basic_verification: "basicVerification",
  supervisor_approval: "supervisorApproval",
  scanning_organizer_qr: "organizerScanning",
  scanning_supervisor_qr: "supervisorScanning",
};

export const eventCheckinVerificationMethods = {
  basicVerification: "basic_verification",
  supervisorApproval: "supervisor_approval",
  organizerScanning: "scanning_organizer_qr",
  supervisorScanning: "scanning_supervisor_qr",
};

export const applicantsProfileStatus = {
  verified: "verified",
  profileCompleted: "profile_completed",
  basicProfileCompleted: "basic_profile_completed",
};

export const genders = {
  male: "male",
  female: "female",
  notProvided: "prefer_not_to_say",
};

export const eventTypes = {
  daily: "daily",
  monthly: "monthly",
};

export const eventPublishingModes = {
  public: "public",
  private: "private",
};
