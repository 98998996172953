import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "@mui/material";

import SearchInput from "./SearchInput";
import FilterInput from "./FilterInput";
import FilterDatePicker from "./FilterDatePicker";
import FilterLabel from "./FilterLabel";

const Filters = ({
  searchInput,
  filters,
  isCollapsable,
  renderActionNextToSearch,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderSearch = () => (
    <div className="d-flex align-items-center">
      <SearchInput
        placeholder={searchInput.placeholder}
        name={searchInput.name}
        value={searchInput.value}
        onChange={searchInput.onChange}
        inputWrapperClass={`${searchInput.inputWrapperClass} ${
          !filters.length && "mb-3"
        } filter-section-search`}
      />
      {renderActionNextToSearch}
    </div>
  );

  const renderFilter = ({
    type,
    name,
    placeholder,
    label,
    value,
    onChange,
    options,
    wrapperClass,
    inputClass,
    disabled,
    isMultiple,
    helperText,
    helperTextClass,
    calendarViews,
    openView,
    inputDateFormat,
    clearfilters,
    isOptionsElt,
  }) =>
    type === "filter" ? (
      <FilterInput
        name={name}
        placeholder={placeholder}
        label={label}
        options={options}
        value={value}
        onChange={onChange}
        disabled={disabled}
        isMultiple={isMultiple}
        filterWrapperClass={wrapperClass}
        inputClassName={inputClass}
        helperText={helperText}
        helperTextClass={helperTextClass}
        isOptionsElt={isOptionsElt}
      />
    ) : type === "date" ? (
      <FilterDatePicker
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        wrapperClass={wrapperClass}
        inputClass={`w-100 ${inputClass}`}
        helperText={helperText}
        helperTextClass={helperTextClass}
        calendarViews={calendarViews}
        openView={openView}
        inputDateFormat={inputDateFormat}
        clear={clearfilters}
      />
    ) : null;

  return (
    <div className="filter-section-container">
      {filters.length >= 3 || isCollapsable ? (
        <>
          <div className="d-flex justify-content-between flex-column flex-md-row mb-3">
            <div className="search-section mb-3 mb-md-0">{renderSearch()}</div>
            <FilterLabel onClick={() => setIsCollapsed(!isCollapsed)} />
          </div>
          <div className="max-filters-container">
            <Collapse direction="up" in={isCollapsed}>
              <div className="row">
                {filters &&
                  filters.map(
                    (filter, index) =>
                      filter && (
                        <div
                          key={index}
                          className={`col-12 col-sm-6 col-md-4 col-lg-3 mb-3 ${filter.className}`}>
                          {renderFilter(filter)}
                        </div>
                      )
                  )}
              </div>
            </Collapse>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="search-section mb-3 mb-md-0">
                {renderSearch()}
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="min-filters-container row justify-content-end">
                {filters &&
                  filters.map(
                    (filter, index) =>
                      filter && (
                        <div key={index} className="col-12 col-md-6 mb-3">
                          {renderFilter(filter)}
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Filters.propTypes = {
  searchInput: PropTypes.objectOf({
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    inputWrapperClass: PropTypes.string,
  }),
  filters: PropTypes.array,
  isCollapsable: PropTypes.bool,
  renderActionNextToSearch: PropTypes.element,
};

export default Filters;
