import * as types from "./types";

// list
export const getEventsRequest = (payload) => ({
  type: types.GET_EVENTS_REQUEST,
  payload,
});
export const getEventsResponse = (payload) => ({
  type: types.GET_EVENTS_RESPONSE,
  payload,
});

export const getEventRequest = (data) => ({
  type: types.GET_EVENT_REQUEST,
  payload: data,
});
export const getEventResponse = (data) => ({
  type: types.GET_EVENT_RESPONSE,
  payload: data,
});

export const addEventRequest = (payload) => ({
  type: types.ADD_EVENT_REQUEST,
  payload,
});

export const editEventRequest = (payload) => ({
  type: types.EDIT_EVENT_REQUEST,
  payload,
});
export const editEventResponse = (data) => ({
  type: types.EDIT_EVENT_RESPONSE,
  payload: data,
});

export const publishEventRequest = (payload) => ({
  type: types.PUBLISH_EVENT_REQUEST,
  payload,
});

export const deleteEventRequest = (payload) => ({
  type: types.DELETE_EVENT_REQUEST,
  payload,
});

export const cancelEventRequest = (payload) => ({
  type: types.CANCEL_EVENT_REQUEST,
  payload,
});

export const setInitialPublishValidationStep = (payload) => ({
  type: types.SET_INITIAL_PUBLISH_VALIDATION_STEP,
  payload,
});

export const inviteApplicantsRequest = (payload) => ({
  type: types.INVITE_APPLICANTS_REQUEST,
  payload,
});

export const showEventConflictWarningAlert = (payload) => ({
  type: types.SHOW_EVENT_CONFLICT_WARNING_ALERT,
  payload,
});
