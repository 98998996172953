import {
  GET_COUNTRIES_RESPONSE,
  GET_LOOKUPS_RESPONSE,
  GET_PROJECT_MANAGERS_RESPONSE,
  GET_EVENT_ZONES_RESPONSE,
  GET_CITIES_RESPONSE,
} from "./types";

const INITIAL_STATE = {
  general: {},
  countries: [],
  cities: [],
  projectManagers: [],
  eventZones: [],
};

const adminRolesList = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOOKUPS_RESPONSE:
      return {
        ...state,
        general: action.payload,
      };
    case GET_COUNTRIES_RESPONSE:
      return {
        ...state,
        countries: action.payload,
      };
    case GET_CITIES_RESPONSE:
      return {
        ...state,
        cities: action.payload,
      };
    case GET_PROJECT_MANAGERS_RESPONSE:
      return {
        ...state,
        projectManagers: action.payload,
      };
    case GET_EVENT_ZONES_RESPONSE:
      return {
        ...state,
        eventZones: action.payload,
      };
    default:
      return state;
  }
};

export default adminRolesList;
