const shared = {
  home: "الرئيسية",
  events: "الفعاليات",
  employees: "الموظفين",
  requests: "الطلبات",
  joiningEvents: "الالتحاق بالفعاليات",
  promotions: "الترقية",
  applicants: "المتقدمين",
  timesheet: "ساعات العمل",
  evaluations: "التقييمات",
  language: "EN",
  englishLang: "الإنجليزية (الولايات المتحدة الأمريكية)",
  arabicLang: "العربية (السعودية)",
  creatEvent: "إضافة فعالية",
  admins: "المديرين",
  addAdmin: "إضافة مدير",
  lookups: "العمليات",
  serviceProviderCategories: "فئات مقدمي الخدمات",
  serviceProviders: "مقدمي الخدمات",
  specialties: "التخصصات",
  services: "الخدمات",
  logout: "تسجيل الخروج",
  lang: "English",
  back: "الرجوع",
  search: "ابحث عن موظف",
  noSearch: "لا توجد نتائج مطابقة لبحثك",
  noData: "لا توجد بيانات حتى الان",
  actions: "أفعال",
  edit: "تعديل",
  view: "تفاصيل",
  delete: "حذف",
  save: "حفظ",
  submit: "ارسال",
  cancel: "الغاء",
  filter: "تصنيف",
  confirm: "تأكيد",
  approve: "قبول",
  block: "حظر",
  unBlock: "الغاء الحظر",
  blocked: "محظور",
  none: "لا يوجد !",
  id: "رقم الهوية",
  viewMore: "مشاهدة المزيد",
  lists: {
    enTitle: "الاسم بالانجليزية",
    arTitle: "الاسم بالعربية",
    enName: "الاسم بالانجليزية",
    arName: "الاسم بالعربية",
    name: "الاسم",
    phone: "رقم الهاتف",
    image: "الصورة",
    email: "البريد الالكتروني",
    categories: "الفئات",
    status: "الحالة",
  },
  filesAccepted: "الحجم الأقصى: 5MB، التنسيقات المقبولة: jpg، jpeg، png، و bmp",
  uploadTypeError: "التنسيقات المقبولة هي jpg و jpeg و png و bmp فقط",
  uploadSizeError: "اقصي حجم 5MB",
  acceptedFormatLabel: "التنسيقات المقبولة",
  noOptions: "لا يوجد خيارات",
  pdfUploadTypeError: "الصيغة المقبولة هي pdf فقط",
  pdfUploadSizeError: "الحجم الأقصى 5MB",
  dynamicTableActions: {
    columnMenuShowColumns: "عرض القوائم",
    columnsPanelTextFieldLabel: "ابحث عن قائمة",
    columnsPanelTextFieldPlaceholder: "عنوان القائمة",
    columnsPanelShowAllButton: "عرض الكل",
    columnsPanelHideAllButton: "إخفاء الكل",
    columnMenuLabel: "قائمة",
  },
  role: "الوظيفه",
  gender: "الجنس",
  male: "ذكر",
  female: "أنثى",
  notProvided: "غير مزود",
  noInternetConnection: "لا يوجد اتصال بالإنترنت",
  generalErrMsg: "عذرا, لقد حدث خطأما!",
  cm: "سم",
  kg: "كجم",
  genderOptions: {
    male: "ذكر",
    female: "أنثى",
    prefer_not_to_say: "غير مُزود",
  },
  status: "الحالة",
  image: "الصورة",
  eventStatusOptions: {
    ongoing: "الحاليه",
    upcoming: "القادمه",
    past: "الماضيه",
    pending: "قيد الانتظار",
    canceled: "ملغيه",
  },
  hint: "لتغيير الوظيفه قبل القبول برجاء التغيير من قائمه الوظائف",
  sar: "ر.س",
  other: "آخر",
  meter: "متر",
  minutes: "دقائق",
  select: "اختر",
  numberOfRows: "عدد الصفوف",
  invite: "ادعو",
  selected: "تم اختيار",
  outOf: "من أصل",
  all: "الكل",
};

export default shared;
